import React, { useEffect } from 'react';
import CookieConsent from 'react-cookie-consent';
import Cookies from 'js-cookie';

const BannerCookieConsent = ({
  setShowBannerCookieContent,
  setShowLegalPage,
}) => {
  useEffect(() => {
    const cookieConsent = Cookies.get('CookieConsent');
    if (cookieConsent === 'false') {
      Cookies.remove('_ga', { domain: '.zaenithweb.fr', path: '/' });
      Cookies.remove('_ga_1QFP49LS4F', { domain: '.zaenithweb.fr', path: '/' });
      console.log(cookieConsent);
    }
  }, []);

  const handleDecline = () => {
    Cookies.remove('_ga', { domain: '.zaenithweb.fr', path: '/' });
    Cookies.remove('_ga_1QFP49LS4F', { domain: '.zaenithweb.fr', path: '/' });
    setShowBannerCookieContent(false);
  };

  return (
    <div className="banner-cookies-container">
      <CookieConsent
        location="bottom"
        buttonText="Accepter"
        declineButtonText="Refuser"
        enableDeclineButton
        onAccept={() => {
          setShowBannerCookieContent(false);
        }}
        onDecline={handleDecline}
        style={{
          background: 'rgb(28 54 74)',
          color: '#FFF',
          fontSize: '16px',
          lineHeight: '1.5em',
          textAlign: 'center',
          position: 'fixed',
          bottom: '20px',
          left: '20px',
          width: '350px',
          borderRadius: '6px',
          padding: '15px',
          boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
          zIndex: 3,
        }}
      >
        Nous utilisons des cookies pour nous aider à analyser notre trafic.
        <br /> En cliquant sur "Accepter", vous consentez à l'utilisation de
        cookies Google Analytics.
        <button
          className="show-legal-btn"
          onClick={() => setShowLegalPage(true)}
        >
          En savoir plus
        </button>
      </CookieConsent>
    </div>
  );
};

export default BannerCookieConsent;

import React from 'react';
import Arrow from '../../assets/images/arrow-list.png';
import Timing from '../../assets/images/timing.png';

const Offers = () => {
  return (
    <section className="section" id="offers">
      <div className="container">
        <div className="section-header">
          <h2 className="hidden">Offres</h2>
          <h3 className="hidden">Attirez, engagez et convertisez</h3>
        </div>
        <section className="section-content wrapper">
          <div className="offer-container">
            <div className="offer-header">
              <h3>Landing Page</h3>
              <p className="subtitle">Convertissez vos prospects</p>
              <p className="price">à partir de 900€</p>
              <p className="timing">
                <img src={Timing} alt="" />
                Livraison : 5 à 7 jours
              </p>
            </div>
            <div className="offer-content">
              <ul>
                <li>
                  <img src={Arrow} alt="" />
                  Création ou intégration de maquette Figma
                </li>
                <li>
                  <img src={Arrow} alt="" />
                  Développement sur mesure ou WordPress
                </li>
                <li>
                  <img src={Arrow} alt="" />
                  Formation WordPress pour éditer votre contenu
                </li>
                <li>
                  <img src={Arrow} alt="" />
                  Design adapté pour tous les écrans
                </li>
                <li>
                  <img src={Arrow} alt="" />
                  Formulaire de contact
                </li>
                <li>
                  <img src={Arrow} alt="" />1 page unique avec CTA clair
                </li>
                <li>
                  <img src={Arrow} alt="" />
                  Mise à jour et corrections : 30 jours aprés livraison
                </li>
              </ul>
            </div>
            <div className="btn-container">
              <a
                href="https://calendly.com/jzanetti1/15min"
                target="_blank"
                rel="noreferrer"
              >
                Prendre rendez-vous
              </a>
            </div>
          </div>
          <div className="offer-container">
            <div className="offer-header">
              <h3>Site vitrine</h3>
              <p className="subtitle">Renforcez votre présence en ligne</p>
              <p className="price">à partir de 1200€</p>
              <p className="timing">
                <img src={Timing} alt="" />
                Livraison : 7 à 15 jours
              </p>
            </div>
            <div className="offer-content">
              <ul>
                <li>
                  <img src={Arrow} alt="" />
                  Création ou intégration de maquette Figma
                </li>
                <li>
                  <img src={Arrow} alt="" />
                  Développement sur mesure ou WordPress
                </li>
                <li>
                  <img src={Arrow} alt="" />
                  Formation WordPress pour éditer votre contenu
                </li>
                <li>
                  <img src={Arrow} alt="" />
                  Design adapté pour tous les écrans
                </li>
                <li>
                  <img src={Arrow} alt="" />
                  Formulaire de contact
                </li>
                <li>
                  <img src={Arrow} alt="" />
                  Jusqu’à 5 page (+150€ par page supplémentaires)
                </li>
                <li>
                  <img src={Arrow} alt="" />
                  Mise à jour et corrections : 45 jours aprés livraison
                </li>
              </ul>
            </div>
            <div className="btn-container">
              <a
                href="https://calendly.com/jzanetti1/15min"
                target="_blank"
                rel="noreferrer"
              >
                Prendre rendez-vous
              </a>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default Offers;

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Ream from '../../assets/images/ream.jpg';
import QualComm from '../../assets/images/qualcomm.jpg';
import Windows11Server from '../../assets/images/w11-server.jpg';
import TdSynnex from '../../assets/images/td-synnex.jpg';
import MetaQuest from '../../assets/images/meta-quest-3s.jpg';
import Windows11Pro from '../../assets/images/windows-11-pro.jpg';
import GreenIt from '../../assets/images/green-it.jpg';
import HpeSmartChoice from '../../assets/images/hpe-smart-choice.jpg';
import HpeNetWorking from '../../assets/images/hpe-aruba-networking.jpg';

import QualCommPdf from '../../assets/images/Qualcomm.pdf';
import WindowsServerPdf from '../../assets/images/Windows-Server.pdf';
import MetaQuestPdf from '../../assets/images/meta-quest.pdf';
import Windows11ProPdf from '../../assets/images/Windows-11-pro-Maquette.pdf';
import GreenItPdf from '../../assets/images/green-it-maquette.pdf';
import HpeArubaPdf from '../../assets/images/HPE-Aruba-Networking.pdf';
import HpeSmartPdf from '../../assets/images/hpe-smart-choice.pdf';

const ContinuousSlider = () => {
  const settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplayspeed: 1500,
    infinite: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="works" id="works">
      <div className="section-header">
        <h2 className="hidden">Réalisations</h2>
        <h3 className="hidden">
          Des projets qui parlent d'eux-mêmes, pour votre succès
        </h3>
      </div>
      <div className="projects-container wrapper">
        <div className="project-container">
          <Slider {...settings} className="slider">
            <div className="slide-item">
              <img src={Ream} alt="Realisation" />
              <div className="infos-content text-center text-lg-start">
                <h4>Reamprod</h4>
                <p>
                  Création de maquette et développement d'un site vitrine pour
                  une association dont le projet est de faire découvrir à tous
                  ses adhérents des talents artistiques
                </p>
                <a
                  href="https://reamprod.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="btn-style"
                >
                  Voir le site
                </a>
              </div>
            </div>
            <div className="slide-item">
              <img src={QualComm} alt="Realisation" />
              <div className="infos-content text-center text-lg-start">
                <h4>Qualcomm</h4>
                <p>
                  Développement d'une landing page pour promouvoir les nouveaux
                  processeurs Qualcomm - Snapdragon
                </p>
                <p className="link">
                  Réalisé pour{' '}
                  <a
                    href="https://fr.tdsynnex.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    TD SYNNEX
                  </a>
                </p>
                <a
                  href={QualCommPdf}
                  target="_blank"
                  rel="noreferrer"
                  className="btn-style"
                >
                  Voir le site
                </a>
              </div>
            </div>
            <div className="slide-item">
              <img src={Windows11Server} alt="Realisation" />
              <div className="infos-content text-center text-lg-start">
                <h4 className="mt-4">Windows Server 2025</h4>
                <p>
                  Développement d'une landing page pour Microsoft afin de
                  promouvoir Windows Server 2025, facilitant la transition des
                  entreprises vers le cloud hybride tout en renforçant la
                  sécurité, la résilience et la productivité
                </p>
                <p className="link">
                  Réalisé pour{' '}
                  <a
                    href="https://fr.tdsynnex.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    TD SYNNEX
                  </a>
                </p>
                <a
                  href={WindowsServerPdf}
                  target="_blank"
                  rel="noreferrer"
                  className="btn-style"
                >
                  Voir le site
                </a>
              </div>
            </div>
            <div className="slide-item">
              <img src={TdSynnex} alt="Realisation" />
              <div className="infos-content text-center text-lg-start">
                <h4 className="mt-4">TD SYNNEX</h4>
                <p>Refonte de la page d'accueil publique de TD SYNNEX</p>
                <a
                  href="https://fr.tdsynnex.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="btn-style"
                >
                  Voir le site
                </a>
              </div>
            </div>
            <div className="slide-item">
              <img src={MetaQuest} alt="Realisation" />
              <div className="infos-content text-center text-lg-start">
                <h4 className="mt-4">Meta</h4>
                <p>
                  Création d'une landing page pour promouvoir le nouveau casque
                  virtuel "Méta Quest 3S"
                </p>
                <p className="link">
                  Réalisé pour{' '}
                  <a
                    href="https://fr.tdsynnex.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    TD SYNNEX
                  </a>
                </p>
                <a
                  href={MetaQuestPdf}
                  target="_blank"
                  rel="noreferrer"
                  className="btn-style"
                >
                  Voir le site
                </a>
              </div>
            </div>
            <div className="slide-item">
              <img src={Windows11Pro} alt="Realisation" />
              <div className="infos-content text-center text-lg-start">
                <h4 className="mt-4">Microsoft</h4>
                <p>
                  Création de maquette et développement d'un site vitrine pour
                  Microsoft afin de promouvoir Windows 11 Professionnel et son
                  intégration avec l'IA
                </p>
                <p className="link">
                  Réalisé pour{' '}
                  <a
                    href="https://fr.tdsynnex.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    TD SYNNEX
                  </a>
                </p>
                <a
                  href={Windows11ProPdf}
                  target="_blank"
                  rel="noreferrer"
                  className="btn-style"
                >
                  Voir le site
                </a>
              </div>
            </div>
            <div className="slide-item">
              <img src={GreenIt} alt="Realisation" />
              <div className="infos-content text-center text-lg-start">
                <h4 className="mt-4">TD SYNNEX - Green IT</h4>
                <p>
                  Développement d'une landing page pour TD SYNNEX afin de
                  promouvoir leur sélection Green IT en mettant en avant des
                  produits éco-responsables
                </p>
                <p className="link">
                  Réalisé pour{' '}
                  <a
                    href="https://fr.tdsynnex.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    TD SYNNEX
                  </a>
                </p>
                <a
                  href={GreenItPdf}
                  target="_blank"
                  rel="noreferrer"
                  className="btn-style"
                >
                  Voir le site
                </a>
              </div>
            </div>
            <div className="slide-item">
              <img src={HpeSmartChoice} alt="Realisation" />
              <div className="infos-content text-center text-lg-start">
                <h4 className="mt-4">Hewlett Packard Enterprise</h4>
                <p>
                  Développement d'une landing page pour Hewlett Packard
                  Enterprise mettant en avant HPE Smart Choice, une solution clé
                  en main permettant de commander des serveurs HPE
                  pré-configurés
                </p>
                <p className="link">
                  Réalisé pour{' '}
                  <a
                    href="https://fr.tdsynnex.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    TD SYNNEX
                  </a>
                </p>
                <a
                  href={HpeSmartPdf}
                  target="_blank"
                  rel="noreferrer"
                  className="btn-style"
                >
                  Voir le site
                </a>
              </div>
            </div>
            <div className="slide-item">
              <img src={HpeNetWorking} alt="Realisation" />
              <div className="infos-content text-center text-lg-start">
                <h4 className="mt-4">Hewlett Packard Enterprise</h4>
                <p className="link">
                  Développement d'une landing page pour Hewlett Packard
                  Enterprise mettant en avant les promotions HPE Aruba
                  Networking, avec des solutions adaptées aux besoins de chaque
                  client, incluant des remises sur des best-sellers
                </p>
                <p className="link">
                  Réalisé pour{' '}
                  <a
                    href="https://fr.tdsynnex.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    TD SYNNEX
                  </a>
                </p>
                <a
                  href={HpeArubaPdf}
                  target="_blank"
                  rel="noreferrer"
                  className="btn-style"
                >
                  Voir le site
                </a>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default ContinuousSlider;

import React from 'react';
import { Link } from 'react-scroll';

const Header = () => {
  return (
    <header id="home">
      <div className="green-blur-circle"></div>
      <div className="container">
        <div className="header-content">
          <h1 className="hidden">
            Sites vitrines et landing pages pour{' '}
            <span>renforcer votre présence en ligne</span> et{' '}
            <span>maximiser vos conversions</span>
          </h1>
          <div className="btns-container">
            <div className="btn-container">
              <Link
                to="offers"
                className="btn-style hidden"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                Voir les offres
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="blue-blur-circle"></div>
    </header>
  );
};

export default Header;

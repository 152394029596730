import React from 'react';
import { Link } from 'react-scroll';

import Logo from '../../assets/images/logo-full.svg';

const Navigation = () => {
  return (
    <>
      <div className="navigation hidden">
        <div className="container">
          <nav>
            <div className="logo-container">
              <Link
                to="home"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                <img src={Logo} alt="ZænithWeb - Freelance Web Developper" />
              </Link>
            </div>
            <ul>
              <li className="link-item works-link">
                <Link
                  to="works"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  Réalisations
                </Link>
              </li>
              <li className="link-item services-link">
                <Link
                  to="offers"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  Offres
                </Link>
              </li>
              <li className="link-item about-link">
                <Link
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  Contact
                </Link>
              </li>
            </ul>
            <div className="btn-container">
              <a
                href="https://calendly.com/jzanetti1/15min?month=2025-02"
                target="_blank"
                rel="noreferrer"
                className="btn-style"
              >
                Réserver un appel
              </a>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Navigation;

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import TdSynnexLogo from '../../assets/images/td-synnex-logo.png';
import MicrosoftLogo from '../../assets/images/microsoft-logo.png';
import SamsungLogo from '../../assets/images/samsung-logo.png';
import HpeLogo from '../../assets/images/hpe-logo.png';
import GoogleLogo from '../../assets/images/google-logo.png';
import QualcommLogo from '../../assets/images/qualcomm-logo.png';

const LogoClient = () => {
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplayspeed: 1500,
    infinite: true,
  };
  return (
    <section className="logos-clients-section">
      <div className="logos-container">
        <div className="logo-container">
          <img src={TdSynnexLogo} alt="TD SYNNEX logo" />
        </div>
        <div className="logo-container">
          <img src={MicrosoftLogo} alt="Microsoft logo" />
        </div>
        <div className="logo-container">
          <img src={SamsungLogo} alt="Samsung logo" />
        </div>
        <div className="logo-container">
          <img src={HpeLogo} alt="Hpe logo" />
        </div>
        <div className="logo-container">
          <img src={GoogleLogo} alt="Google logo" />
        </div>
        <div className="logo-container">
          <img src={QualcommLogo} alt="Qualcomm logo" />
        </div>
      </div>
      <div className="slider-container hidden">
        <Slider {...settings} className="slider">
          <div className="slide-item">
            <img src={TdSynnexLogo} alt="TD SYNNEX logo" />
          </div>
          <div className="slide-item">
            <img src={MicrosoftLogo} alt="TD SYNNEX logo" />
          </div>
          <div className="slide-item">
            <img src={SamsungLogo} alt="TD SYNNEX logo" />
          </div>
          <div className="slide-item">
            <img src={HpeLogo} alt="TD SYNNEX logo" />
          </div>
          <div className="slide-item">
            <img src={GoogleLogo} alt="TD SYNNEX logo" />
          </div>
          <div className="slide-item">
            <img src={QualcommLogo} alt="TD SYNNEX logo" />
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default LogoClient;

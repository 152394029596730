import React from 'react';
import Header from '../../components/Sections/Header';
import LogoClient from '../../components/Sections/LogoClient';
import Works from '../../components/Sections/Works';
import Offers from '../../components/Sections/Offers';
import Contact from '../../components/Sections/Contact';
import ScrollAnim from '../../components/ScrollAnim/ScrollAnim';

const Home = () => {
  return (
    <main className="home">
      <ScrollAnim />
      <Header />
      <LogoClient />
      <Works />
      <Offers />
      <Contact />
    </main>
  );
};

export default Home;
